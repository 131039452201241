import request from '@/api/request.js'

/**
 * @Description: 收货信息
 */

// 分页查询
export function pageWmsConsignee(data) {
    return request({
        url: '/api/wmsConsignee/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsConsignee(data) {
    return request({
        url: '/api/wmsConsignee/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsConsignee(data) {
    return request({
        url: '/api/wmsConsignee/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsConsignee(data) {
    return request({
        url: '/api/wmsConsignee/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsConsignee(data) {
    return request({
        url: '/api/wmsConsignee/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsConsignee(data) {
    return request({
        url: '/api/wmsConsignee/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsConsignee(data) {
    return request({
        url: '/api/wmsConsignee/batchDelete',
        method: 'POST',
        data
    })
}

