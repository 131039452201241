
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="编辑单据"
    :visible.sync="visible"
    width="1000px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        
        <el-col :span="24">
                <el-form-item label="开团易ID" prop="orderId">
              <el-input v-model="row.orderId" placeholder="请输入开团易ID" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="来源单号" prop="erpId">
              <el-input v-model="row.erpId" placeholder="请输入来源单号" clearable
                :disabled="true"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="单据场景" prop="model">
                <el-select v-model="row.model"
                :disabled="true"
                clearable
                placeholder="请选择单据场景">
                    <el-option
                          v-for="item in enums['WmsInOutOrderModelEnum']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                </el-select>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="单号" prop="code">
              <el-input v-model="row.code" placeholder="请输入单号" clearable
                :disabled="true"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="单据类型" prop="type">
                <el-select v-model="row.type"
                :disabled="true"
                clearable
                placeholder="请选择单据类型">
                    <el-option
                          v-for="item in enums['WmsInOutOrderTypeEnum']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                </el-select>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="仓库" prop="warehouseName">
              <el-input v-model="row.warehouseName" placeholder="请输入仓库" clearable
                :disabled="true"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="客户" prop="customerName">
              <el-input v-model="row.customerName" placeholder="请输入客户" clearable
                :disabled="true"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="供应商" prop="supplierName">
              <el-input v-model="row.supplierName" placeholder="请输入供应商" clearable
                :disabled="true"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                      </el-col>
                      </el-row>

                      <el-row>
                      <el-col>

                <el-form-item label="明细" prop="details">

<vxe-table
        :data="row.details"
        empty-text=""
        :scroll-y="{enabled: false}"
        :edit-config="{trigger: 'click', mode: 'cell'}"
        ref="detailsTable"
>

            <vxe-column field="id" title="ID" :visible="false" ></vxe-column>









        <vxe-column field="skuName" title="商品" >
            <template slot-scope="scope">
                        <span>{{scope.row.skuName}}</span>
            </template>
        </vxe-column>


        <vxe-column field="skuSpec" title="规格" >
            <template slot-scope="scope">
                        <span>{{scope.row.skuSpec}}</span>
            </template>
        </vxe-column>


        <vxe-column field="skuCode" title="商品编码" >
            <template slot-scope="scope">
                        <span>{{scope.row.skuCode}}</span>
            </template>
        </vxe-column>


        <vxe-column field="skuBarcode" title="商品条码" >
            <template slot-scope="scope">
                        <span>{{scope.row.skuBarcode}}</span>
            </template>
        </vxe-column>






        <vxe-column field="unitName" title="单位" >
            <template slot-scope="scope">
                        <span>{{scope.row.unitName}}</span>
            </template>
        </vxe-column>


        <vxe-column field="quantity" title="数量" >
            <template slot-scope="scope">
                    <el-input-number v-model="scope.row.quantity"
                                     :disabled="false"
                                     placeholder="请输入数量" clearable
                                     :controls="false"
                                     :step="1"
                                     :style="{width: '120px'}"></el-input-number>
            </template>
        </vxe-column>




        <vxe-column field="price" title="单价(元)" >
            <template slot-scope="scope">
                    <el-input-number v-model="scope.row.price"
                                     :disabled="true"
                                     placeholder="请输入单价(元)" clearable
                                     :step="0.001"
                                     :precision="3"
                                     :controls="false"
                                     :style="{width: '120px'}"></el-input-number>
            </template>
        </vxe-column>


        <vxe-column field="actualQuantity" title="实收数量" >
            <template slot-scope="scope">
                        <el-input v-model="scope.row.actualQuantity" placeholder="请输入实收数量" clearable
                                  :disabled="false"
                                  :style="{width: '80px'}"></el-input>
            </template>
        </vxe-column>


        <vxe-column field="actualUnitId" title="实收单位" >
            <template slot-scope="scope">
                    <el-select ref="actualUnitId"
                               :disabled="false"
                               v-model="scope.row.actualUnitId"
                               prop="actualUnitId"
                               placeholder="请选择实收单位"
                               clearable
                               filterable
                               :filter-method="listWmsUnit"
                               :style="{width: '80px'}"
                    >
                        <el-option
                                v-for="item in optionsOfWmsUnit"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
            </template>
        </vxe-column>




        <vxe-column field="receiveStatus" title="收货状态" >
            <template slot-scope="scope">
                    <el-select v-model="scope.row.receiveStatus"
                               :disabled="false"
                               clearable
                               placeholder="请选择收货状态"
                               :style="{width: '80px'}"
                    >
                        <el-option
                                v-for="item in enums['WmsInOutDetailReceiveStatus']"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                        </el-option>
                    </el-select>
            </template>
        </vxe-column>


        <vxe-column field="binId" title="货位" >
            <template slot-scope="scope">
                    <el-select ref="binId"
                               :disabled="false"
                               v-model="scope.row.binId"
                               prop="binId"
                               placeholder="请选择货位"
                               clearable
                               filterable
                               :filter-method="listWmsBinLocation"
                               :style="{width: '120px'}"
                    >
                        <el-option
                                v-for="item in optionsOfWmsBinLocation"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                        ></el-option>
                    </el-select>
            </template>
        </vxe-column>
















</vxe-table>                </el-form-item>
                            </el-col>
                            </el-row>

                            <el-col>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="快递公司" prop="expressCo">
              <el-input v-model="row.expressCo" placeholder="请输入快递公司" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="快递单号" prop="expressId">
              <el-input v-model="row.expressId" placeholder="请输入快递单号" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="备注" prop="remark">
              <el-input v-model="row.remark" placeholder="请输入备注"
                :disabled="false"
                    clearable
                    type="textarea"
                    :rows="3"
                    :style="{width: '200px'}"
                ></el-input>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="24">
                <el-form-item label="完成时间" prop="completeTime">
                <el-date-picker
                :disabled="false"
                      v-model="row.completeTime"
                      type="datetime"
                      placeholder="选择日期时间">
                </el-date-picker>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { editWmsInOutOrder } from "@/api/wms/wmsInOutOrder"

import { listWmsWarehouse } from "@/api/wms/wmsWarehouse"

import { listWmsCustomer } from "@/api/wms/wmsCustomer"

import { listWmsSupplier } from "@/api/wms/wmsSupplier"

import { listWmsInOutOrder } from "@/api/wms/wmsInOutOrder"
import { listWmsSku } from "@/api/wms/wmsSku"
import { listWmsUnit } from "@/api/wms/wmsUnit"
import { listWmsBinLocation } from "@/api/wms/wmsBinLocation"
import { listWmsConsignee } from "@/api/wms/wmsConsignee"


export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            orderId: null,
            erpId: null,
            model: null,
            code: null,
            status: null,
            type: null,
            warehouseId: null,
            warehouseName: null,
            customerId: null,
            customerName: null,
            supplierId: null,
            supplierName: null,
            details: null,
            expressCo: null,
            expressId: null,
            consigneeId: null,
            remark: null,
            loginUserId: null,
            loginUserName: null,
            completeTime: null,
      },
      rules: {
            model: [
                { required: true, message: '请输入单据场景!'},
            ],
            type: [
                { required: true, message: '请输入单据类型!'},
            ],
            warehouseId: [
                { required: true, message: '请输入仓库!'},
            ],
            details: [
                { required: true, message: '请输入明细!'},
            ],
            details: [
                { required: true, message: '请输入明细!'},
            ],
      },
        enums: {},
        enumMaps: {},
        optionsOfWmsWarehouse: [],

        optionsOfWmsCustomer: [],

        optionsOfWmsSupplier: [],

        optionsOfWmsInOutOrder: [],
        optionsOfWmsSku: [],
        optionsOfWmsUnit: [],
        optionsOfWmsBinLocation: [],
        optionsOfWmsConsignee: [],

    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
    this.listWmsWarehouse()

    this.listWmsCustomer()

    this.listWmsSupplier()

    this.listWmsInOutOrder()
    this.listWmsSku()
    this.listWmsUnit()
    this.listWmsBinLocation()
    this.listWmsConsignee()

  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('WmsInOutOrderModelEnum')
          names.push('WmsInOutOrderStatus')
          names.push('WmsInOutOrderTypeEnum')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen(row) {
        this.visible = true
        this.row = row
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },
    listWmsWarehouse(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsWarehouse(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsWarehouse = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsCustomer(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsCustomer(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsCustomer = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsSupplier(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSupplier(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSupplier = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsInOutOrder(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsInOutOrder(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsInOutOrder = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsSku(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSku(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSku = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsUnit(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsUnit(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsUnit = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsBinLocation(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsBinLocation(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsBinLocation = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsConsignee(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsConsignee(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsConsignee = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        editWmsInOutOrder(param).then(res=>{
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
